/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/home", false)}`}
              aria-haspopup="true"
          >
            <NavLink exact className="menu-link" to="/home">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Home</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {/*begin::1 Level*/}

          {/*end::1 Level*/}

          {/* Components */}
          {/* begin::section */}
          <li className="menu-section ">
            <h4 className="menu-text">Convert from ASIN</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/asin-to-price", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/asin-to-price">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")}/>
            </span>
              <span className="menu-text">ASIN to Price</span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/asin-to-image", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/asin-to-image">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Pictures1.svg")}/>
            </span>
              <span className="menu-text">ASIN to Image</span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/asin-to-title", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/asin-to-title">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Chat1.svg")}/>
            </span>
              <span className="menu-text">ASIN to Title</span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/asin-to-description", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/asin-to-description">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Chat2.svg")}/>
            </span>
              <span className="menu-text">ASIN to Description</span>
            </NavLink>
          </li>
          <li
              className={`menu-item ${getMenuItemActive("/asin-to-bullet-points", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/asin-to-bullet-points">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")}/>
            </span>
              <span className="menu-text">ASIN to Bullet Points</span>
            </NavLink>
          </li>
          {/* end:: section */}

         
          {/* Applications */}
          {/* begin::section */}
          <li className="menu-section ">
            <h4 className="menu-text">Convert to ASIN </h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/* end:: section */}

          {/* eCommerce */}
          {/*begin::1 Level*/}
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/e-commerce", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="" disabled='true'>
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")}/>
            </span>
              <span className="menu-text">Coming Soon</span>
            </NavLink>
            
          </li>

          
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
