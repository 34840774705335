import React, {Suspense, lazy} from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {LayoutSplashScreen, ContentRoute} from "../_metronic/layout";
import {BuilderPage} from "./pages/BuilderPage";
import {MyPage} from "./pages/MyPage";
import {DashboardPage} from "./pages/DashboardPage";
import Home from './pages/Home';

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                <Redirect exact from="/" to="/home"/>
                <Route path="/home" component={Home}/>
                <Route path="/asin-to-price" component={GoogleMaterialPage}/>
                <Route path="/asin-to-image" component={GoogleMaterialPage}/>
                <Route path="/asin-to-title" component={GoogleMaterialPage}/>
                <Route path="/asin-to-description" component={GoogleMaterialPage}/>
                <Route path="/asin-to-bullet-points" component={GoogleMaterialPage}/>
                <Redirect to="/error"/>
            </Switch>
        </Suspense>
    );
}
